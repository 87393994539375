.storeWrapper {
  max-height: 95vh;
}
.noData {
  position: relative;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 25%;
    height: 400px;
    width:700px
}
.noData .msg {
  font-size: 20px;
}
