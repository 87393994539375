
.editerPopupmnbox{padding: 40px 0; display: flex;align-items: center;justify-content: center;background-color: rgba(0,0,0,.7);z-index: 26;width: 100%;height: 100%;position: fixed;top: 0;}
.editerPopupmnbox .editerPoplatinerbox{width: 800px;height: 500px;}
.editerpopInermnbox{position: relative;display: flex;align-items: center;border-radius: 2px;overflow: hidden;}
.popRgtletpopbox{position: absolute;padding: 0 20px; display: flex;width: 100%;}
.popRgtletpopbox button{padding: 9px 15px;border-radius: 2px;display: flex;align-items: center;justify-content: center;}
.popRgtletpopbox button.active{background: #fff;}
.crosbtboxpop{position: absolute;top: 10px;right: 10px;}
.crosbtboxpop button{width: 24px;height: 24px;background: #fff;border-radius: 2px;display: flex;align-items: center;justify-content: center;}
.editAddsildebt{position: absolute;bottom: 20px; width: 100%;display: flex;justify-content: center;}
.editAddsildebt button{padding: 8px 42px;background: #17a8e5;color: #fff;font-size: 12px;border-radius: 2px;}