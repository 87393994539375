.toolSetBox {
  position: fixed;
  top: 50px;
  width: calc(100% - 240px);
  display: flex;
  align-items: center;
  border-top: 0.5px solid #b0b0b0;
  border-bottom: 0.5px solid #b0b0b0;
  z-index: 9;
  background-color: #fff;
  justify-content: space-between;
}
.logoHeadBox {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 10;
}
.logoHeadBox .logoBx {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoHeadBox .logoBx ~ .logoBx {
  border-left: 0.5px solid #b0b0b0;
}
.leftToolBox {
  display: flex;
  align-items: center;
}
.leftToolBox .arrowNumBox {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-right: 0.5px solid #b0b0b0;
}
.leftToolBox .arrowNavBox {
  padding: 4px 5px;
  width: max-content;
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: solid 0.5px #b0b0b0;
}
.leftToolBox .arrowNavBox .arrowBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  background: #f8f8f8;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}
.leftToolBox .arrowNavBox .arrowBtn ~ .arrowBtn {
  margin-left: 10px;
}
.leftToolBox .pageNumBox {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.leftToolBox .pageNumBox .page {
  font-size: 12px;
  color: #000;
}
.leftToolBox .pageNumBox .activePage {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 0.5px #b0b0b0;
}
.leftToolBox .pageNumBox .totalPage {
  margin-left: 5px;
  padding-left: 6px;
  position: relative;
}
.leftToolBox .pageNumBox .totalPage::before {
  content: "/";
  position: absolute;
  left: 0;
}
.leftToolBox .miniToolKit {
  display: flex;
  align-items: center;
}
.leftToolBox .miniToolKit .tool {
  padding: 0 10px;
  height: 40px;
  border: none;
  outline: none;
  border-right: 0.5px solid #b0b0b0;
  background: #fff;
  cursor: pointer;
  position: relative;
}
.leftToolBox .miniToolKit .tool .toolinercolbox {
  padding: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #b0b0b0;
}
.fileNameBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  font-size: 12px;
  color: #000;
  background: #fff;
}
.rightToolBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.rightToolBox .logedIn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  width: 7px;
  height: 7px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-bottom: -4px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -4px;
}
.leftToolBox .miniToolKit .tool:not(:disabled):hover .toolbtclilckd {
  display: flex;
}
.leftToolBox .miniToolKit .tool:disabled:hover {
  cursor: not-allowed;
}
.toolbtclilckd {
  box-shadow: 0 5px 15px 0 rgba(119, 119, 119, 0.25);
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #b0b0b0;
  display: none;
}
.toolSubMenu{white-space: nowrap;}
.tollbtbtcling {
  padding: 5px 18px;
  border: 1px solid #b0b0b0;
  position: relative;
}
.tollbtbtcling:hover {
  background-color: #e9f8ff;
}
.toolbtclilckd .toolSubMenu:last-child {
  border-right: none;
}
.toolbtclilckd .toolSubMenu:first-child .tollbtbtcling:hover {
  /* background-color: #fff; */
}
.tollbtbtfisbt {
  padding: 12px;
  border-bottom: 1px solid #b0b0b0;
}
.tollbtbtfisbt:last-child {
  border-bottom: none;
}
.tollbtbtfisbt:hover {
  background-color: #e9f8ff;
}
.tollsbCokobox {
  display: flex;
  align-self: center;
  padding: 5px;
  background: #fff;
  border-radius: 2px;
}
.toolTitle {
  position: relative;
}

.toolTitle {
  position: absolute;
  display: none;
  border: 0.5px solid #D0D0D0 ;
  background-color: #EFEFEF;
  color: #222;
  padding: 10px;
  border-radius: 2px;
  font-size: 10px;
  bottom: 115%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tool:hover .toolTitle {
  display: block;
}

.lineTitle {
  position: absolute;
  display: none;
  border: 0.5px solid #D0D0D0 ;
  background-color: #EFEFEF;
  color: #222;
  padding: 10px;
  border-radius: 2px;
  font-size: 10px;
  bottom: -140%;
  left: 0;
  transform: translateX(-50%);
  z-index: 99;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tollbtbtcling:hover .lineTitle {
  display: block;
}

.dropDown {
  min-width: 125px;
  height: 40px;
  border-right: 0.5px solid #b0b0b0;
  border-left: 0.5px solid #b0b0b0;
  position: relative;
}
.downloadPdf {
  height: 40px;
}
.downloadPdf .btn {
  background: #17a8e5;
  padding: 7px 10px;
  color: #fff;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  height: 100%;
}
.downloadPdf .btn:hover {
  background-color: #53c7f7;}

.slidecontainer {
  width: 100%; /* Width of the outside container */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
}
.strokeValue{background-color: #dadbdc;font-size: 10px;padding: 5px;font-weight: 600;}
.activeTool {
  outline: auto !important;
  outline-width: 2px; /* Set the width of the outline */
  outline-style: solid; /* Set the style of the outline */
  outline-color: #17a8e5 !important; /* Set the color of the outline */
}
.labelsFlex{display: flex;gap: 5px;margin-bottom: 5px;}
.labelsFlex input[type="checkbox"]{margin: 0;background-color: #e5e5e5;}
.labelsFlex input[type="checkbox" i]:disabled { background-color: #e5e5e5;}
.zoomLables{font-size: 10px;min-width: 55px;}
.tooltopPading{padding: 5px !important;}
.labelsFlex input[type="checkbox"]:checked:disabled {
  background-color: #e5e5e5; 
  position: relative;
  border-radius: 2px ;
}
.labelsFlex input[type="checkbox"]:checked:disabled::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5; 
  top: 0;
  left: 0;
}

.zoomInput {
  width: 35px;
  text-align: left;
  margin-left: 5px;
  font-size: 10px;
  border: 1px solid #b0b0b0;
}

.font10{
  font-size: 10px;
}