.dEditmainpopbox{display: flex; align-items: center; justify-content: center; background-color: rgba(0,0,0,.7); height: 100%; width: 100%; position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 15;}
.dEditmainpopbox .dediterInermbox{width: 480px;}
.dediterInermbox .editerpopHead{padding: 12px 16px;background: #053456;display: flex;align-items: center;justify-content: space-between;border-radius: 2px 10px 0 0;}
.editerpopHead .editerGallbox{display: flex;align-items: center;gap: 10px;}
.materBedmiabox{padding: 16px;border: 1px solid #b0b0b0;height: 600px;background: #fff;overflow: auto;display: flex;align-items: center;flex-direction: column;gap: 5px;}
.materBedmiabox .masteDhead{display: flex;align-items: center;justify-content: space-between;}
.materBedmiabox .lftRgtbtbox{display: flex;align-items: center;gap: 10px;}
.materBedmiabox .lftRgtbtbox button{width: 20px;height: 20px;box-shadow: 0 4px 10px 0 rgba(169, 169, 169, 0.25);background: #fff;border-radius: 2px;display: flex;align-items: center;justify-content: center;}
.imageWrapper{border-bottom: 1px solid #053456;}
.masterbadImgtiles{border-radius: 2px;width: 98px;position: relative;}
.masterbadImgtiles img{border-radius: 2px;}
.poupStrimgbox{background-color: rgba(0, 0, 0, 0.5); border: solid 1px rgba(208, 208, 208, 0.87);border-radius: 2px;width: 99px;height: 65px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0px;left: 0px;display: none;}
.masterbadImgtiles:hover .poupStrimgbox{display: block;display: flex;align-items: center;justify-content: center;}
.masterbeDmaimgb{display: grid;grid-template-columns: repeat(4,1fr);grid-gap: 10px; overflow: auto; max-height: 510px;padding-bottom: 8px;}
