.statusCommentBox{background: #f8f8f8;overflow: auto;cursor: pointer;max-height: 550px;}
/* .statusCommentBox .commentsWrapper{max-height: 550px; overflow-y: scroll;} */
.statusCommentBox .commentBox{padding: 10px;border-radius: 2px;background: #fff;max-height: 300px;height: fit-content; overflow-y: auto;}
/* .statusCommentBox .commentBox:hover{background-color: #afddf1;} */
.statusCommentBox .commentSec{position: relative; padding: 10px;border-bottom: solid 0.5px #b0b0b0;}
.userStatBox{display: flex;align-items: center;justify-content: flex-end;border-bottom: solid 0.5px #b0b0b0;padding: 8px 0px;}
.commentBox .user{display: flex;align-items: center;margin-top: 10px;}
.commentBox .user .avatar{width: 20px;height: 20px;padding: 3px 6px;border-radius: 2px;display: flex;align-items: center;justify-content: center;color: #fff;font-size: 10px;}
.commentBox .user .name{margin-left: 5px;font-size: 10px;color: #000;font-weight: 600;}
.commentBox .userStat{display: flex;align-items: center;}
.commentBox .userStat .threeDot{margin-left: 10px;cursor: pointer;}
.commentBox .msgs{border-bottom: solid 0.5px #b0b0b0;padding:2px 5px;}
.commentBox .msgs:hover{background-color: #afddf1;}
.commentBox .msgs:last-child{border-bottom: none}
/* .commentSec .avatar{background: #e57b17;} */
/* .commentSec:nth-child(2) .avatar{background: #aa6bdc;} */
.commentSec .replyStat{display: block;margin-top: 10px;color: #17a8e5;font-size: 12px;cursor: pointer;}
.commentBox .comment{margin-top: 10px;font-size: 10px;color: #000;text-align: justify;line-height: 15px;max-height: 100px;height: fit-content;overflow:auto;word-wrap: break-word;margin-bottom: 5px;}
.commBoxclikbt{box-shadow: 0 5px 15px 0 rgba(119, 119, 119, 0.25);position: absolute;top: 50px;right: 15px; border-radius: 2px;background: #fff;cursor: pointer;}
.commBoxclikbt .tollbtbtcling { padding: 2px 15px;border-bottom: 1px solid #b0b0b0;}
.commBoxclikbt .tollbtbtcling:hover {background-color: #17a8e5; }
.commBoxclikbt .tollbtbtcling:last-child{border: none;}
.lowerRlybox{display: flex;align-items: center;justify-content: space-between;border-top: 1px solid #b0b0b0;background: #fff}
.lowerRlybox input{flex: 1; padding: 12px;}
.lowerBoximg{padding: 4px 8px;border-radius: 2px;cursor: pointer}