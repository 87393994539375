/* HandleList.module.css */
.container {
    width: 100%;
    border: 1px solid #e5e7eb;
    border-radius: 2px;
    background-color: #ffffff;
    overflow: hidden;
  }
  
  .header {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr auto;
    gap: 16px;
    padding: 12px 16px;
    background-color: #f3f4f6;
    border-bottom: 1px solid #e5e7eb;
    align-items: center;
  }
  
  .headerCell {
    font-size: 12px;
    color: #6b7280;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .bodyContainer {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr auto;
    gap: 16px;
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
    align-items: center;
  }
  
  .row:last-child {
    /* border-bottom: none; */
  }
  
  .imageCell {
    display: flex;
    align-items: center;
  }
  
  .image {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    object-fit: cover;
  }
  
  .moduleInfo, .handleDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .positionCell, .sizeCell {
    display: flex;
    align-items: center;
  }
  
  .primaryText {
    font-size: 12px;
    font-weight: 500;
  }
  
  .secondaryText {
    font-size: 12px;
    color: #6b7280;
  }
 
  .header,
.row {
  display: grid;
  grid-template-columns: 80px 1fr 0.9fr 1fr 1fr 2fr 80px;
  gap: 16px;
}
.customRequest{
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #f6f6f6; */
  border-radius: 2px;
  border: 2px solid #f6f6f6;
  position: absolute;
  bottom: 10px;
  left: 0;
  margin-left: 25%;
}
.customRequest .customHeader{
  display: flex;
  align-items: center;
  gap: 16px;
}
.customRequest .customHeader:nth-child(){
  border-right: none;
}