.rightSideBar{position: fixed;right: 0;top:50px;width: 240px;height: calc(100vh - 50px); border-left: solid 0.5px #b0b0b0;background-color: #fff;z-index: 1;display: flex;flex-direction: column;}
.rightSideBar .tabBox{display: flex;align-items: center;border-bottom: solid 0.5px #b0b0b0;border-top: solid 0.5px #b0b0b0;}
.rightSideBar .tabBox li{line-height: 40px;width: 50%;font-size: 12px;color: #000;text-align: center;cursor: pointer;background: #f8f8f8;position: relative;}
.rightSideBar .tabBox li.active{color: #17a8e5;}
.rightSideBar .tabBox li.active::before{position: absolute;bottom: 0;left: 0;content: '';width: 100%;height: 2px;background: #17a8e5;}
.rightSideBar .tabBox li ~ li {border-left: solid 0.5px #b0b0b0;}
.reqDocBox{width: 100%;background: #daf4ff;padding: 10px;position: absolute;bottom: 0;}
.reqDocBox .btn{width: 100%;background: #17a8e5;padding: 7px 10px;color: #fff;justify-content: center;border-radius: 2px;}
.reqDocBox .btn:hover{background-color: #53c7f7;}
.reqDocBox .btn:disabled{cursor: not-allowed;background-color: #e5e5e5; color: #666666;}
.reqDocBox .btn:disabled:hover{background-color: #e5e5e5;}
.dqcActions{width: 100%;background: #daf4ff;padding: 10px;position: absolute;bottom: 0;display: flex ;gap:10px;}
.dqcActions .aprBtn{width: 100%;background: #17a8e5;padding: 7px 10px;color: #fff;justify-content: center;border-radius: 2px; cursor: pointer;}
.dqcActions .aprBtn:hover{background-color: #53c7f7;}
.dqcActions .aprBtn:disabled{cursor: not-allowed;background-color: #e5e5e5; color: #666666;}
.dqcActions .aprBtn:disabled:hover{background-color: #e5e5e5;}
.dqcActions .rjctBtn{width: 100%;background: #ffff;padding: 7px 10px;color: #17a8e5;justify-content: center;border-radius: 2px; cursor: pointer;border: 1px solid #17a8e5;}
.dqcActions .rjctBtn:hover{background-color: #eaf8ff;color: #17a8e5;}
.dqcActions .rjctBtn:disabled{cursor: not-allowed;background-color: #ffffff; color: #666666;border: 1px solid #666666;}
.dqcActions .rjctBtn:disabled:hover{background-color: #ffffff;}

.toolTitle {
    position: absolute;
    visibility: hidden;
    width: 90%;
    opacity: 0;
    background-color: #17a8e5;
    color: white;
    padding: 7px 10px;
    border-radius: 2px;
    font-size: 14px;
    bottom: calc(100% + 5px); /* Adjust distance from the button */
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: flex;
    justify-content: inherit;
    gap: 10px;
    align-items: center;
    text-align: start;
  }
  
  .btn:disabled:hover .toolTitle {
    visibility: visible;
    opacity: 1;
  }
  .rjctBtn:disabled:hover .toolTitle {
    visibility: visible;
    opacity: 1;
  }
  .aprBtn:disabled:hover .toolTitle {
    visibility: visible;
    opacity: 1;
  }