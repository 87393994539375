
.boqSection {
  padding: 24px 0px;
  background-color: #f8f8f8;
  /* height: calc(100vh - 150px); */
}
.containerBqc {
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 1150px;
}
.productTile .title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #222;
}
.productSection {
  padding: 15px;
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 2px;
  border: solid 0.5px #bdbdbd;
}
.productHeading {
  border-radius: 2px;
  border: solid 0.5px #bdbdbd;
  background-color: #f8f8f8;
}
.productHeading th {
  padding: 16px;
}
.productDetail td{padding: 10px 15px;border-bottom: 1px dashed #666;}
.productDetail figure {width: 50px;height: 50px;display: flex;overflow: hidden;border-radius: 2px;}
.totalAmount{ padding: 16px 16px 16px 30px;border-radius: 2px;border: solid 0.5px #eb5976;display: flex;align-items: center;justify-content: space-between;background-color: #fff3f6;}
.totalAmount li{font-size: 16px;font-weight: 500;color: #222;}
.totalAmount li:last-child{text-align: right;}
.totalAmount em{display: block;font-size: 10px;color: #666;line-height: normal;font-style: normal;}

/* .mobileTiles{display: none;}
.mobileTiles .mobileHeading{margin-bottom: 10px;font-size: 12px;font-weight: 500;font-weight: 500;line-height: normal;}
.mobileTiles .tile{margin-bottom: 15px;display: block;background-color: #f8f8f8;border: solid 0.5px #bdbdbd;border-radius: 5px;}
.mobileTiles .tile.active .otherDetails{display: block;}
.mobileTiles .tileHeading{padding: 5px 10px;font-size: 10px;line-height: normal;border-bottom: solid 0.5px #bdbdbd;display: flex;align-items: center;}
.mobileTiles .tileHeading figure{margin-right: 15px;max-width: 50px;max-height: 50px;width: 100%;height: 100% ;display: flex;overflow: hidden;}
.mobileTiles .tileHeading img{max-width: 50px;object-fit: cover;border-radius: 8px;overflow: hidden;height: 50px;}
.mobileTiles .tileHeading label{display: block;}
.mobileTiles .tileHeading span{font-weight: 600;}
.mobileTiles .tileHeading .viewDetailBtn {padding: 0px 10px;background-color: #eb5976;color: #fff;border-radius: 2px;cursor: pointer;font-size: 10px;line-height: 22px;white-space: nowrap;}
.mobileTiles .tileHeading .headingDesc{width: 100%;}
.mobileTiles .tileDetails{padding: 10px;border-bottom: solid 0.5px #bdbdbd;font-size: 10px;}
.mobileTiles .tileDetails label{display: block;line-height: normal;}
.mobileTiles .tileDetails span{font-weight: 600;}
.mobileTiles .amountStruct{padding: 10px;background: #fff;display: flex;}
.mobileTiles .amountStruct li{width: 100%;font-size: 10px;}
.mobileTiles .amountStruct li label{margin-bottom: 5px;display: block;line-height: normal;}
.mobileTiles .amountStruct li span{display: block;line-height: normal;font-weight: 600;}
.mobileTiles .otherDetails{font-size: 10px;background: #fff;display: none;}
.mobileTiles .otherDetails li{padding: 10px;display: flex;border-bottom: solid 0.5px #bdbdbd;}
.mobileTiles .otherDetails figure{margin-right: 15px;width: 50px;height: 50px;display: flex;overflow: hidden;}
.mobileTiles .otherDetails img{max-width: 50px;object-fit: cover;border-radius: 8px;overflow: hidden;}
.mobileTiles .otherDetails .itemName{margin-bottom: 10px;line-height: normal;}
.mobileTiles .otherDetails .itemDesc{line-height: normal;}
.mobileTiles .otherDetails .itemDesc span{font-weight: 600;} */



/* @media screen and (max-width: 768px) {
  .productTile {
    display: none;
  }
  .mobileTiles{
    display: block;
  }
} */
