.miscellaneousWrapper {
  height: fit-content;
  margin-top: 15px;
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 100%;
  background: #f8f8f8;
  border-top: 1px solid #000;
}
.miscellaneousWrapper .header {
  font-size: small;
  padding: 5px;
  color: #000;
  margin-top: 10px;
  /* background-color: antiquewhite; */
}
.miscellaneousWrapper .misComments {
  overflow-y: scroll;
  max-height: 300px;
}
.miscellaneousWrapper .commentsWrapper .commentSec{position: relative; padding: 10px;border-bottom: solid 0.5px #b0b0b0;}
.commentSec .commentBox{padding: 10px;border-radius: 2px;background: #fff;max-height: 300px;height: fit-content; overflow-y: auto;}
.commentBox .userStatBox{display: flex;align-items: center;justify-content: flex-end;border-bottom: solid 0.5px #b0b0b0;padding: 8px 0px;}
.commentBox .userStat{display: flex;align-items: center;}
.commentBox .userStat .threeDot{margin-left: 10px;cursor: pointer;}
.commBoxclikbt{box-shadow: 0 5px 15px 0 rgba(119, 119, 119, 0.25);position: absolute;top: 50px;right: 15px; border-radius: 2px;background: #fff;cursor: pointer;}
.commBoxclikbt .tollbtbtcling { padding: 2px 15px;border-bottom: 1px solid #b0b0b0;}
.commBoxclikbt .tollbtbtcling:hover {background-color: #17a8e5; }
.commBoxclikbt .tollbtbtcling:last-child{border: none;}
.commentBox .msgs{border-bottom: solid 0.5px #b0b0b0;padding:2px 5px;}
.commentBox .msgs:hover{background-color: #afddf1;}
.commentBox .msgs:last-child{border-bottom: none}
.commentBox .msgs .user{display: flex;align-items: center;margin-top: 10px;}
.commentBox .msgs .user .avatar{width: 20px;height: 20px;padding: 3px 6px;border-radius: 2px;display: flex;align-items: center;justify-content: center;color: #fff;font-size: 10px;}
/* .commentSec .avatar{background: #17a8e5;} */
.commentBox  .msgs .user .name{margin-left: 5px;font-size: 10px;color: #000;font-weight: 600;}
.commentBox .comment{margin-top: 10px;font-size: 10px;color: #000;text-align: justify;line-height: 15px;max-height: 100px;height: fit-content;overflow:auto;word-wrap: break-word;margin-bottom: 5px;}
.lowerRlybox{display: flex;align-items: center;justify-content: space-between;border-top: 1px solid #b0b0b0;background: #fff}
.lowerRlybox input{flex: 1; padding: 12px;}
.lowerBoximg{padding: 4px 8px;border-radius: 2px;cursor: pointer}
.commentSec .replyStat{display: block;margin-top: 10px;color: #17a8e5;font-size: 12px;cursor: pointer;}

