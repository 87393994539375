.successWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  display: flex;
}
.boundary {
  border-radius: 2px;
  background-color: #fff;
  width: 332px;
  height: 220px;
  margin: auto;
  color: aliceblue;
  position: relative;
  /* padding: 30px 0 0; */
  display: flex;
  flex-direction: column;
  border: #17a8e5 1px solid;
}
.boundary .cross {
  position: absolute;
  color: #17a8e5;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #17a8e5 1px solid;
}
.boundary .cross:hover {
  background-color: #17a8e5;
  color: #fff;
}
.upperBox {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.upperBox .message {
  color: #222222;
  font-size: 15px;
  padding: 10px 15px;
  text-align: center;
}
.lowerBox {
  /* width: 330px; */
  /* height: 62px; */
  margin: 16px 0 0;
  padding: 16px 40px;
  /* object-fit: contain; */
  background-color: #daf4ff;
  /* display: flex; */
  /* gap: 15px; */
  border-radius: 0px 0px 2px 2px;
}
.lowerBox .accept {
  width: 100%;
  /* height: 30px; */
  padding: 7px 49px;
  border-radius: 2px;
  background-color: #17a8e5;
  color: #fff;
  cursor: pointer;
}
.lowerBox .accept:hover {
  background-color: #53c7f7;
}
