.leftSideBar {
  position: fixed;
  left: 0;
  top: 92px;
  width: 182px;
  border-right: solid 0.5px #b0b0b0;
  height: calc(100vh - 92px);
  z-index: 1;
}
.leftSideBar .addSlide {
  padding: 7px;
  color: #222;
  font-size: 12px;
  line-height: normal;
  background: #f3f0f0;
  border-bottom: solid 0.5px #b0b0b0;
  text-align: center;
  cursor: pointer;
}
.leftSideBar .addSlide:hover {
  background-color: #17a8e5;
  color: #fff;
}
.leftSideBar .selectAll {
  padding: 7px;
  color: #222;
  font-size: 12px;
  line-height: normal;
  background: #fff;
  border-bottom: solid 0.5px #b0b0b0;
  text-align: center;
  cursor: pointer;
}
.leftSideBar .selectAll:hover {
  background-color: #17a8e5;
  color: #fff;
}
.leftSideBar .slidePages {
  padding: 20px;
  height: calc(100% - 60px);
  overflow: auto;
  background: #f8f8f8;
  border-top: solid 0.5px #b0b0b0;
}
.slidePages .pageBox {
  border: 1px solid #b0b0b0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}
.slidePages .pageBox.active {
  border: solid 3px #17a8e5;
  border-radius: 2px;
}
.slidePages .pageBox.notExcluded {
  /* border: solid 3px #17a8e5; */
}
.slidePages .pageBox.excluded {
  border: solid 3px red;
}
.slidePages .pageBox .detIocnbtbox {
  padding: 5px 7px;
  background: #ffe8e8;
  border-radius: 2px;
  position: absolute;
  bottom: 30px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9;
}
.slidePages .pageBox .detIocnbtbox img{cursor: pointer;}
.slidePages .pageBox .slideStatus {
  /* padding: 5px 7px; */
  border-radius: 2px;
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.slidePages .pageBox .slideStatus .resolved {
    background-color: #38B84D;
}
.slidePages .pageBox .slideStatus .open {
    background-color: #FF8A00;
}
.slidePages .pageBox .slideStatus .approved {
    background-color: #17a8e5;
}
.slidePages .pageBox .slideStatus .Dot {
  width: 10px;
  height: 10px;
  /* background-color: rgb(0, 255, 38); */
  border-radius: 50%;
}
.slidePages .pageBox ~ .pageBox {
  margin-top: 20px;
}
.slidePages .pageBox .slide {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  background: #fff;
  width: 100%;
  height: 100px;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 4px 5px 0 rgba(155, 155, 155, 0.25);
  background: #daf4ff;
}
.slidePages .pageBox .pageNo {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #b0b0b0;
  border-radius: 0 0 2px 2px;
}
.slidePages .pageBox .pageNo .slideName {
  white-space: nowrap;
  text-transform: capitalize;
  font-size: 12px;
}
.slidePages .pageBox .pageNo .pgnBerimgbox {
  background: #fff;
  width: 16px;
  height: 16px;
  border: 1px solid #666;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slidePages .pageBox .pageNo .pgnBerimgbox.active {
  background: #68d379;
}
.slidePages .pageBox .emptyPage {
  background: #daf4ff;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slidePages .pageBox .emptyPage .emptySlide {
  background: #fff;
  width: 80%;
  height: 80px;
}
.pdfSectionBox {
  padding: 0 330px 0 272px;
}
.leftSideBar ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: absolute;
  z-index: 10000;
}
.leftSideBar ::-webkit-scrollbar-thumb {
  background-color: #17a8e5;
  border-radius: 2px;
}
.leftSideBar ::-moz-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  z-index: 10000;
  right: 0;
}
.leftSideBar ::-moz-scrollbar-thumb {
  background-color: #17a8e5;
  border-radius: 2px;
}
