.zoomInOut {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 40px;
  border-right: 0.5px solid #b0b0b0;
  cursor: pointer;
  justify-content: space-around;
  padding: 0px 5px;
  position: relative;
}
.lock{position: absolute;top: -3px;}