/* components/Magnifier.module.css */

.wrapper {
  position: relative;
  overflow: hidden;
}

.handle {
  position: absolute;
  height: calc(var(--size) * 1.9);
  width: calc(var(--size) * 1.9);
  background-size: contain;
  filter: drop-shadow(-2px -2px 10px rgba(0, 0, 0, 0.5));
  pointer-events: none;
  z-index: 1000;
}
.hidden {
    opacity: 0;
    visibility: hidden;
}

.visible {
    opacity: 1;
    visibility: visible;
}

.magnifyingGlass {
  position: absolute;
  padding: 0;
  margin: 0;
  height: var(--size);
  width: var(--size);
  border: solid 1px black;
  background: radial-gradient(#f5fcfc 45%, gray);
  overflow: hidden;
  border-radius: 10%;
}

.magnifyingGlass::after {
  display: block;
  position: relative;
  content: "";
  top: calc(2 * var(--size) / 3);
  left: calc(2 * var(--size) / 3);
  height: 15px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  transform: rotate(-45deg);
  opacity: 70%;
}

.bodyClone {
  position: absolute;
  padding: inherit;
  height: 690px;
  width: 1041px;
  transform: scale(var(--scale));
}